//import VueQr from "vue-qr";
export default {
  //components: { VueQr },
  data() {
    return {
      url: "",
      urls: "",
      op: "",
      code: "",
      scode: "",
      urlsCode: ""
    };
  },

  created() {
    this.urlsCode = window.location.href;
    let redirect_uri = encodeURIComponent(document.location.protocol + "//" + window.location.host + "/weChat/oauth2-login?OP=1008&u=" + this.$route.query.u);
    this.url = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxf99fad9253c6594d&redirect_uri=" + redirect_uri + "&response_type=code&scope=snsapi_base&state#wechat_redirect";
  },

  mounted() {
    if (this.$sopen.isWechat() == true) {
      this.authorize();
    }
  },

  methods: {
    authorize() {
      window.location.href = this.url;
    },

    uscode() {
      var data = {
        module: "wechat",
        title: "uscode",
        data: {
          appid: this.op,
          code: this.code
        }
      };
      let that = this;
      this.$sopen.requestApi(data).then(res => {
        that.scode = res;
      });
    }

  }
};