var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _vm._m(0);
};

var staticRenderFns = [function () {
  var _vm = this,
      _c = _vm._self._c;

  return _c('div', {
    staticClass: "about"
  }, [_c('h3', [_vm._v("正在跳转..")])]);
}];
export { render, staticRenderFns };